import React, {Component} from "react";
import Layout from "../components/layout";
import Brand from "../components/Brand";
import {graphql} from "gatsby";
import Breadcrumb from "../components/Breadcrumb";

import {CgbBrandLinkRowBlock} from "../components/blocks/CgbBrandLinkRowBlock";

class Product extends Component {
    render() {

      let otherBrandBlocks = [
        {
          attributes: {
            align: "",
            anchor: "",
            backgroundColor: "",
            className: "",
            content: "Other Brands",
            level: 2,
            textColor: "",
            __typename: "WpCoreHeadingBlockAttributes"
          },
          innerBlocks: [],
          name: "core/heading",
          __typename: "WpCoreHeadingBlock",
        },
      ];

      this.props.data.allWpBrand.edges.forEach(brand => {
        otherBrandBlocks.push({
          attributes: {
            className: brand.node.slug,
            ctaLink: "/about-us/" + brand.node.slug,
            ctaText: "About",
          },
          innerBlocks: [
            {
              attributes: {
                align: "",
                backgroundColor: "",
                className: "",
                content: brand.node.BrandExtras.introductionHeader,
                direction: "",
                textColor: "",
                __typename: "__WpCoreParagraphBlockAttributes"
              },
              innerBlocks: [],
              name: "core/paragraph",
              __typename: "WpCoreParagraphBlock",
            },
          ],
          name: "cgb/brand-link",
          __typename: "WpCgbBrandLinkBlock",
        })
      });

      otherBrandBlocks.push({
        attributes: {
          className: "cronex",
          ctaLink: "https://cronexuk.com/",
          ctaText: "About",
        },
        innerBlocks: [
          {
            attributes: {
              align: "",
              backgroundColor: "",
              className: "",
              content: "Cronex is our export range, distributing the best plumbing supplies worldwide.",
              direction: "",
              textColor: "",
              __typename: "__WpCoreParagraphBlockAttributes"
            },
            innerBlocks: [],
            name: "core/paragraph",
            __typename: "WpCoreParagraphBlock",
          },
        ],
        name: "cgb/brand-link",
        __typename: "WpCgbBrandLinkBlock",
      })

      otherBrandBlocks.push({
        attributes: {
          className: "resan",
          ctaLink: "https://www.dudleyresan.co.uk/",
          ctaText: "About",
        },
        innerBlocks: [
          {
            attributes: {
              align: "",
              backgroundColor: "",
              className: "",
              content: "We manufacture anti-ligature sanitary ware products for many market sectors.",
              direction: "",
              textColor: "",
              __typename: "__WpCoreParagraphBlockAttributes"
            },
            innerBlocks: [],
            name: "core/paragraph",
            __typename: "WpCoreParagraphBlock",
          },
        ],
        name: "cgb/brand-link",
        __typename: "WpCgbBrandLinkBlock",
      })
      
      return (
        <Layout
          meta={{
            ...this.props.data.wpBrand.Meta,
            canonical: this.props.data.wpBrand.uri
          }}
          path={this.props.pageContext.pagePath}
          cta={this.props.data.wpBrand.PageCTA}
          themeOptions={this.props.data.wp.themeOptions}
        >
          <Breadcrumb
            type="brand"
            current={{
              title: this.props.data.wpBrand.name,
              slug: this.props.data.wpBrand.slug,
              uri: this.props.data.wpBrand.uri
            }}
          />
          <Brand
            name={this.props.data.wpBrand.name}
            data={this.props.data.wpBrand.BrandExtras}
          />
          <CgbBrandLinkRowBlock
            attributes={{
              className: "",
            }}
            innerBlocks={otherBrandBlocks}
          />
        </Layout>
      )
    }
}

export const query = graphql`
  query ($id: String) {
    allWpBrand(filter: {id: {ne: $id}}) {
      edges {
        node {
          id
          slug
          name
          uri
          BrandExtras {
            introductionHeader
          }
        }
      }
    }
    wpBrand(id: {eq: $id}) {
      id
      slug
      name
      uri
      Meta {
        fieldGroupName
        metaDescription
        metaTitle
        ogDescription
        ogTitle
        twitterCardType
        twitterDescription
        twitterTitle
        ogImage {
          uri
        }
        twitterImage {
          uri
        }
      }
      BrandExtras {
        logo {
          localFile {
            childImageSharp {
              fluid(quality: 80, maxWidth: 1660) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        backgroundTexture {
          localFile {
            publicURL
            childImageSharp {
              fluid(quality: 80, maxWidth: 1660) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        backgroundColor
        introductionHeader
        introductionCopy
        factsBenefits {
          heading
          content
          image {
            localFile {
              childImageSharp {
                fluid(quality: 80, maxWidth: 1660) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        brandRange {
          id
          name
          uri
          productCategory {
            categoryImage {
              localFile {
                childImageSharp {
                  fluid(quality: 80, maxWidth: 1660) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
        bannerImage {
          localFile {
            publicURL
            childImageSharp {
              fluid(quality: 80, maxWidth: 1660) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        bannerContent
        latestProducts {
          ... on WpProduct {
            id
            title
            uri
            link
            featuredImage {
              node {
                sourceUrl
              }
            }
            product {
              buyOffline
              buyOnline
              code
              description
              fieldGroupName
              fixDescription
              fixGuide
              fixVideo
              hasHowtoGuide
              installDescription
              installGuide
              installVideo
              introduction
              overview
              shortDescription
              productGallery {
                id
                localFile {
                  childImageSharp {
                    fluid(quality: 80, maxWidth: 1660) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            brands {
              nodes {
                BrandExtras {
                  logo {
                    localFile {
                      childImageSharp {
                        fluid(quality: 80, maxWidth: 1660) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    wp {
      themeOptions {
        ThemeOptions {
          siteLogo {
            mediaItemUrl
          }
        }
      }
    }
  }
`;

export default Product

import React from 'react';
import { makeStyles } from '@material-ui/styles';
import styles from './styles';
import Img from "gatsby-image";
import ReactHtmlParser from "react-html-parser";
import Link from '../Link';

import ProductsGrid from "../Products/Components/ProductsGrid.js";

import { CoreHeadingBlock } from "../../components/blocks/CoreHeadingBlock";

const useStyles = makeStyles(theme => styles(theme));

export default function Brand(props) {

    const classes = useStyles();

    let count = 0;

    return <>
        <div className={`brand-page ${classes.brandPage}`}>
            {props.data.backgroundTexture !== null &&
                <div className="background" style={{backgroundColor: props.data.backgroundColor, backgroundImage: 'url(' + props.data.backgroundTexture.localFile.publicURL}}></div>
            }
            {props.data.backgroundTexture === null &&
                <div className="background" style={{backgroundColor: props.data.backgroundColor}}></div>
            }
            {props.data.logo !== null &&
                <Img className="brand-logo" fluid={props.data.logo.localFile.childImageSharp.fluid} />
            }
            <div className="intro-columns">
                <div className="intro">
                    <h2>{ReactHtmlParser(props.data.introductionHeader)}</h2>
                    {ReactHtmlParser(props.data.introductionCopy)}
                </div>
                {props.data.factsBenefits !== null &&
                    <div className="facts">
                        {props.data.factsBenefits.map((fact) => {
                            count++;
                            return <div className="fact-block" key={"fact-" + count}>
                                <div className="content">
                                    <h3>{ReactHtmlParser(fact.heading)}</h3>
                                    <p>{ReactHtmlParser(fact.content)}</p>
                                </div>
                                <div className="image">
                                    {fact.image !== null &&
                                        <Img fluid={fact.image.localFile.childImageSharp.fluid} />
                                    }
                                </div>
                            </div>
                        })}
                    </div>
                }
            </div>
            {props.data.brandRange !== null &&
                <div className="product-range">
                    <h2>Product Range</h2>
                    <div className="cat-grid">
                        {props.data.brandRange.map((cat) => {
                            return <Link to={cat.uri} key={cat.id} className="cat-link">
                                {cat.productCategory.categoryImage !== null &&
                                    <Img fluid={cat.productCategory.categoryImage.localFile.childImageSharp.fluid} />
                                }
                                <span className="cat-title">{cat.name}</span>
                            </Link>
                        })}
                    </div>
                </div>
            }
            <div className="callout">
                {props.data.bannerImage !== null &&
                    <Img fluid={props.data.bannerImage.localFile.childImageSharp.fluid}/>
                }
                <p>{ReactHtmlParser(props.data.bannerContent)}</p>
            </div>
            <div className="latest-products">
                {props.data.latestProducts !== null && 
                <CoreHeadingBlock
                    attributes={{
                        align: "",
                        anchor: "",
                        className: "",
                        content: "Latest Products",
                        level: 3,
                        textColor: "foreground_primary",
                        backgroundColor: "",
                        __typename: "WpCoreHeadingBlockAttributes",
                    }}
                    innerBlocks={[]} 
                />
                }
                <ProductsGrid products={props.data.latestProducts} />
            </div>
        </div>
        
    </>
}